@catalog-image-height: 300px;

.footer-menu {
  .list-inline;
  margin-bottom: 0px;
}

#divSetLanguage {
    position: absolute;
    right: 30px;
    bottom: 20px;
}

@media(max-width: @screen-xs-max){
  .logo-responsive-img-size {
    width: 130px;
  }

  #divHeaderOut {
    height: 75px;
  }

  #divLogoField2 {
    padding-top: 0px;
    margin-left: 25px;
    text-align: left;
  }

  #divSetLanguage {
    width: 70px;
    right: 0px;
    bottom: 12px;
  }

  #divSetLanguage > div {
    margin-left: 5px;
    margin-right: 5px;
    right: 10px;
  }
}

.catalog-image {
  .img-responsive;
  .center-block;
  max-height: (@catalog-image-height - 30);
}

.catalog-image-container {
  height: @catalog-image-height;
}

.catalog-image-container a.catalog-link-desc::after {
  content: "\00a0\00BB";
}

.small-form-control {
  .form-control;
  display: initial;
  width: 60px;
}

.catalog-image-container a.catalog-link-desc {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
}

#divContentContainer h1:first-child, #divContentContainer h2:first-child, #divContentContainer h3:first-child, #divContentContainer h4:first-child, #divContentContainer h5:first-child, #divContentContainer h6:first-child {
  margin-top: 0px;
}

#formArticleSearch .input-group {
  width: 100%;
}

@media (min-width: @screen-sm-min) {
  #formArticleSearch {
    width: 180px;
  }
}

@media (min-width: @screen-md-min) {
  #formArticleSearch {
    width: 300px;
  }
}

@media (min-width: @screen-lg-min) {
  #formArticleSearch {
    width: 400px;
  }
}

.SliderTextHeader:hover{
  color: white;
}

.SliderTextHeader:visited{
  color: white;
}

.SliderTextHeader:active{
  color: white;
}

.SliderTextText:hover{
  color: white;
}

.SliderTextText:visited{
  color: white;
}

.SliderTextText:active{
  color: white;
}

.MainNavStandard:hover{
  color: white;
}

.MainNavStandard:visited{
  color: white;
}

.MainNavStandard:active{
  color: white;
}

.SliderBlockStandard1 {
  background-color: #006E45;
}

@media screen and (max-width: 640px){
  .SliderBlockStandard {
    background-color: #006E45;
    bottom: 0px;
    height: auto;
    left: 0px;
    padding: 5px;
    right: 0px;
    width: 100%;
    position: absolute;
    opacity: 0.8;
    top: initial;
  }
}

#divHomeContent {
    width: 100%;
    min-height: 540px;
}

.LeftMenuMainText {
    background-color: #ececec;
    color: #0b1f42;
    line-height: 1.5em;
    margin-top: 0.5em;
    padding-left: 1em;
    text-decoration: none;
    text-transform: uppercase;
    list-style-type: none;
    min-width: 240px;
}

/*
#divSwitch {
    bottom: 0px;
    position: relative;
    right: 0px;
    margin-top: 5px;
}
*/

#divSwitch_1 {
    position: relative;
    margin-top: 10px;
}

.CustomerMenuBox {
    border-width: 0px;
    padding: 20px;
    width: 315px;
    z-index: 1000;
}

.CustomerMenuBox input.btn {
  width: 100%;
}

@media screen and (max-width: 640px){
  .CustomerMenuBox {
      width: 100%;
  }
}

.LoginEntry {
    background-color: #FFFFFF;
    border-color: #0b1f42;
    border-style: solid;
    border-width: 1px;
    color: #606060;
    font-size: 1em;
    font-style: italic;
    font-weight: 200;
    line-height: 1em;
    width: 99%;
    font-family: 'Source Sans Pro', sans-serif;
    vertical-align: middle;
}

.ArticleDescRegisterActiv {
    background-color: #ececec;
    color: #005f6a;
    float: left;
    font-size: 1.333em;
    height: initial;
    padding-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    position: relative;
    text-transform: uppercase;
}

@media screen and (max-width: 640px){
  .HideByDevice {
      display: initial;
  }
}

.LeftMenuSubText {
    background-color: #ececec;
    color: #0b1f42;
    line-height: 1.2em;
    text-decoration: none;
    text-transform: capitalize;
    margin-top: 2px;
    margin-bottom: 2px;
}

@media screen and (max-width: 640px){

}

.SliderTextHeader {
    color: #ffffff;
    font-size: 1.333em;
    margin: 0px;
    padding: 0px;
}

.SliderTextHeader {
    color: #ffffff;
    font-size: 1.333em;
    margin: 0px;
    padding: 0px;
}

.SliderTextText {
    color: #ffffff;
    font-size: 1em;
    margin: 4px;
}

.SliderTextHeader {
    color: #ffffff;
    font-size: 1.333em;
    margin: 4px;
    padding: 0px;
}

.SliderTextHeader1 {
  color: #ffffff;
  font-size: 1.666em;
}

.SliderTextHeader1:hover {
  color: #ffffff;
}

.SliderTextText1 {
  color: #ffffff;
  font-size: 1.000em;
}

.SliderTextText1:hover {
  color: #ffffff;
}

.SliderBlockStandard1 {
  bottom: 0px;
  position: absolute;
  color: #ffffff;
  background-color: #006E45;
  padding: 10px;
}

@media (min-width: @screen-sm-min) {
  .SliderBlockStandard1 {
      background-color: #006E45;
      bottom: 40px;
      height: auto;
      padding: 10px;
      position: absolute;
      right: 72px;
      width: 380px;
      opacity: 0.8;
  }

  .SliderTextHeader1 {
    color: #ffffff;
    font-size: 1.667em;
    font-weight: normal;
    line-height: 1em;
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1em;
  }

  .SliderTextText1 {
    color: #ffffff;
    font-size: 1.333em;
    margin: 4px;
    padding: 0px;
    margin-left: 0px;
    margin-top: 5px;
  }

  .SliderTextHeader {
    color: #ffffff;
    font-size: 1.333em;
    margin: 0px;
    padding: 0px;
  }

  .SliderTextText {
    color: #ffffff;
    font-size: 1em;
    margin-left: 0px;
    margin-top: 5px;
  }
}

span.control-label, div.control-label {
  &:extend(label);
}

#formArticleSearch .input-group-addon {
  width: 51px;
}

select {
  -moz-appearance: none;
}

select::-ms-expand {
    width:12px;
    border:none;
    background:#fff;
}

#LinkDescription {
  height: 100%;
}

#LinkComments {
  height: 100%;
}

#divHeaderOut {
  position: relative;
}

.article-overview-image {
  .img-responsive;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.article-overview-item {
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
}

.sales-badge {
  position: absolute;
  top: 0px;
  left: 0px;
}

.row-mb-20 {
  margin-bottom: 20px;
}

@media (max-width: @screen-sm-max) {
  #divPageSelector {
    padding-top: 10px;
  }
}

.OldPriceArticleOverview {
  font-weight: bold;
  text-decoration: line-through;
}

#divArticleInfoRefresh_1 {
  margin-top: 5px;
}

.PriceBoldBig {
    font-size: 1.333em;
    font-weight: 400;
}

.Standard {
    color: #606060;
    font-size: 18px;
    font-weight: 300;
    line-height: 18px;
    font-family: 'Source Sans Pro', sans-serif!important;
}

.breadcrumb {
  margin-bottom: 0px;
  margin-top: 10px;
}

.pagination {
  &:extend(ul);

  margin-top: 0px;
  margin-bottom: 0px;
}

.pagination > a, .pagination > span {
  &:extend(.pagination>li>a);
}

.pagination-sm > a, .pagination-sm > span {
  &:extend(.pagination-sm>li>a);
}

.pagination > a:first-child, .pagination > span:first-child {
  &:extend(.pagination>li:first-child>a);
}

.pagination > a:last-child, .pagination > span:last-child {
  &:extend(.pagination>li:last-child>a);
}

.pagination>.active, .pagination>.active:focus, .pagination>.active:hover {
  &:extend(.pagination>.active>a);
}

.pagination > a:hover, .pagination > span:hover, .pagination > a:focus, .pagination > span:focus {
  &:extend(.pagination > li > a:hover);
}

.list-prepend-icon::before {
  content: '<span class="glyphicon-chevron-right"></span>';
}

.list-sub-group {
  padding-left: 20px;
  padding-bottom: 5px;
}

.list-sub-group > li {
  display: list-item;
  margin: 10px 0px;
}

.list-group-item.active a, .list-group-item.active a:hover, .list-group-item.active a:focus, .list-group-item.active a:visited {
  color: white;
}

.list-group-item.active .glyphicon-chevron-right:before {
  color: @gray-lighter;
}

.list-group-item.active a, .list-group-item.active a:hover, .list-group-item.active a:focus, .list-group-item.active a:visited {
  color: @gray-lighter;
}

.glyphicon-chevron-right:before {
  color: @gray-lighter;
}

.glyphicon-chevron-right-active:before {
  &:extend(.glyphicon-chevron-right:before);
  color: black;
}

#fld1100{
  margin-top: 20px;
  margin-bottom: 20px;
}

#sta1108{
  margin-top: 20px;
  margin-bottom: 20px;
}

.Galery {
    height: 200px;
    margin-top: -1em;
    position: relative;
    width: 4em;
    Overflow: auto;
}

.divShoppingBasket {
 margin-bottom: 10px;
 width: 40px;
 height:40px;
 display:block;
 background:transparent url('/goodheim/CustomUpload/374O357O340O370O356O369O350O358O366O366O355O359O356O360O364O/WebPortal/Portal-goodheim-1/img/Step1g.png') center top no-repeat;
}

.divShoppingBasket:hover {
   background-image: url('/goodheim/CustomUpload/374O357O340O370O356O369O350O358O366O366O355O359O356O360O364O/WebPortal/Portal-goodheim-1/img/Step1g.png');
}

#divSocialLinksArticle{
  margin-top: 20px;
}

#linkShoppingBasket{
  font-size: 20px;
}

#divDescription_1{
  margin-top: 20px;
}

#cmdBasket{
  margin-top: 20px;
  width: 230px;
}

#cmdNote{
  margin-top: 20px;
  width: 230px;
}

.GaleryMainPic {
    border-width: 0px;
    margin-right: 0.5em;
    //margin-top: 20px;
    position: relative;
    float: left;
    max-width: 100%;
}

.FancyBoxGaleryPics {
    text-decoration: none;
    max-width: 490px;
}

@media screen and (max-width: 640px){
  .Galery {
      height: 4em;
      padding-top: 0.0em;
      width: 100%;
  }
}

#divActArticle{
  margin-top: 20px;
}

#divArticleHeadline_7{
  margin-top: 40px;
  margin-bottom: 20px;
}

.basketentry {
  height: 1.4em;
  text-align: center;
  width: 3.2em;
}

.leafletentry {
  margin-right: 0.5em;
  text-align: center;
  width: 3.2em;
}

.leafletlabel {
  display: inline-block;
  margin-right: 0.5em;
}

.ArticleDescOverview {
    height: 50px;
}

.btn-fa {
  font-family: FontAwesome, "Source Sans Pro";
  width: 100% !important;

  .center-block;
  max-width: 200px;
}

#formArticleCatalog h2:first-of-type {
  margin-top: 0px;
}

.gallery-row > div {
  padding-bottom: 20px;
}

.catalog-article-prices {
  height: 3em;
}

.h-divider {
  height: 1px;
  width:100%;
  display:block;
  margin: 9px 0;
  overflow: hidden;
  background-color: @gray-lighter;
}

.text-left-not-xs, .text-left-not-sm, .text-left-not-md, .text-left-not-lg {
    text-align: left;
}
.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg {
    text-align: center;
}
.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg {
    text-align: right;
}
.text-justify-not-xs, .text-justify-not-sm, .text-justify-not-md, .text-justify-not-lg {
    text-align: justify;
}

@media (max-width: 767px) {
    .text-left-not-xs, .text-center-not-xs, .text-right-not-xs, .text-justify-not-xs {
        text-align: inherit;
    }
    .text-left-xs {
        text-align: left;
    }
    .text-center-xs {
        text-align: center;
    }
    .text-right-xs {
        text-align: right;
    }
    .text-justify-xs {
        text-align: justify;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .text-left-not-sm, .text-center-not-sm, .text-right-not-sm, .text-justify-not-sm {
        text-align: inherit;
    }
    .text-left-sm {
        text-align: left;
    }
    .text-center-sm {
        text-align: center;
    }
    .text-right-sm {
        text-align: right;
    }
    .text-justify-sm {
        text-align: justify;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .text-left-not-md, .text-center-not-md, .text-right-not-md, .text-justify-not-md {
        text-align: inherit;
    }
    .text-left-md {
        text-align: left;
    }
    .text-center-md {
        text-align: center;
    }
    .text-right-md {
        text-align: right;
    }
    .text-justify-md {
        text-align: justify;
    }
}
@media (min-width: 1200px) {
    .text-left-not-lg, .text-center-not-lg, .text-right-not-lg, .text-justify-not-lg {
        text-align: inherit;
    }
    .text-left-lg {
        text-align: left;
    }
    .text-center-lg {
        text-align: center;
    }
    .text-right-lg {
        text-align: right;
    }
    .text-justify-lg {
        text-align: justify;
    }
}
